import { Box, Button } from "@mui/material";
import { Loader } from "atoms";
import { ClientXmlFileForm, PageHead } from "components";
import {
    actionsData,
    identifiersData,
    namesData,
    tabsData,
    urlsData,
} from "data";
import { useBatchNamesSelectionList, useEditClient } from "hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

const ClientXmlFileTab = () => {
    const { t } = useTranslation();

    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const location = useLocation();

    const { id } = useParams();

    const { loading: batchNamesSelectionListLoading } = useBatchNamesSelectionList();

    const {
        editClient,
        loading,
    } = useEditClient(
        parseInt(id),
        tabsData.entities.clientForm[1]?.key,
    );

    const {
        url: clientsRouteUrl,
        xmlFile: {
            edit: editXmlFileRouteUrl,
            preview: previewXmlFileRouteUrl,
        },
    } = urlsData.routes.clients;

    const { button: buttonId } = identifiersData.ids;

    const {
        editXmlFile: editXmlFileButtonName,
        previewXmlFile: previewXmlFileButtonName,
    } = namesData.buttons;

    const treeObj = currentClient?.treeObj;

    const isApple = currentClient?.isApple;

    const isUpdated = currentClient?.isUpdated;

    const formValues = {
        batchName: currentClient?.distributeBatchName?.id,
        takedownFile: treeObj?.takedownPath,
        xmlFile: treeObj?.filePath,
    };

    if (batchNamesSelectionListLoading) return <Loader />;

    return (
        <Box>
            <PageHead title={t("tabs.xmlFile")} />
            {!!(treeObj || isUpdated) && !isApple && (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={2}
                    justifyContent="start"
                >
                    {treeObj && (
                        <Link
                            state={{ from: location.pathname + location.search }}
                            to={`${clientsRouteUrl}/${id}${editXmlFileRouteUrl}`}
                        >
                            <Button
                                id={`${editXmlFileButtonName}_${buttonId}`}
                                variant="contained"
                            >
                                {t("clients.editXmlFile")}
                            </Button>
                        </Link>
                    )}
                    {(!!isUpdated && !isApple) && (
                        <Link
                            state={{ from: location.pathname + location.search }}
                            to={`${clientsRouteUrl}/${id}${previewXmlFileRouteUrl}`}
                        >
                            <Button
                                id={`${previewXmlFileButtonName}_${buttonId}`}
                                variant="contained"
                            >
                                {t("clients.previewXmlFile")}
                            </Button>
                        </Link>
                    )}
                </Box>
            )}
            <ClientXmlFileForm
                action={actionsData.edit}
                loading={loading}
                values={formValues}
                onSubmitForm={editClient}
            />
        </Box>
    );
};

export default ClientXmlFileTab;
