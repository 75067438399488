import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { AutoCompleteMenu, FileLink, UploadInput } from "atoms";
import { FormContainer } from "components";
import {
    clientXmlFileFormData,
    fileData,
    inputData,
    permissionsData,
} from "data";
import { useUploadFile } from "hooks";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useClientXmlFileSchema } from "schemas";
import { canDo, renderFormControllerHandler, setUploadInputPreviousValueHandler } from "utils";

const ClientXmlFileForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const [finalizedFieldsArray, setFinalizedFieldsArray] = useState([]);

    const { t } = useTranslation();

    const selections = useSelector((state) => state.selectionsReducer);

    const currentClient = useSelector((state) => state.clientsReducer)?.currentClient;

    const {
        isApple,
        takedownDownloadUri,
        xmlDownloadUri,
    } = currentClient;

    const schema = useClientXmlFileSchema();

    const { id } = useParams();

    const { user } = useAuth0();

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    } = useUploadFile();

    const {
        mediaEdit: mediaEditPermission,
        mediaRead: mediaReadPermission,
    } = permissionsData.permissions.keys;

    const allowedPermissions = [mediaReadPermission, mediaEditPermission];

    useEffect(
        () => {
            const filteredFields = isApple
                ? clientXmlFileFormData.filter((field) => !field.notApple)
                : clientXmlFileFormData.filter((field) => field.name !== fileData.names.takedown);

            setFinalizedFieldsArray(filteredFields);
        },
        // eslint-disable-next-line
        [isApple, clientXmlFileFormData],
    );

    const xmlFileUploadHandler = (file, fieldName, isTakedown) => {
        const currentFile = file;
        let uploadType = fieldName;
        let renamedFile = null;
        if (isApple) {
            const newName = isTakedown ? `${currentClient.name}/takedown.xml` : `${currentClient.name}/metadata.xml`;

            renamedFile = new File(
                [currentFile],
                newName,
                {
                    lastModified: currentFile.lastModified,
                    type: currentFile.type,
                },
            );
            uploadType = isTakedown ? fileData.names.takedown : fileData.names.xmlType;
        }

        uploadFile(
            renamedFile || currentFile,
            formMethods,
            uploadType,
            parseInt(id),
        );
    };

    const {
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
    } = formMethods;
    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        __,
        selectionKey,
    ) => {
        switch (type) {
        case inputData.types.file:
            return (
                <Box mb={1}>
                    <UploadInput
                        accept={fileData.acceptedExtensions.xml}
                        color={errors[name]?.message ? "error" : "primary"}
                        error={errors[name]?.message}
                        field={field}
                        hasError={!!errors[name]?.message}
                        label={t(`labels.${label}`)}
                        loading={filesLoading?.[name]}
                        disabled={!canDo(
                            user,
                            allowedPermissions,
                        )}
                        onChange={(e) => xmlFileUploadHandler(
                            e.target.files[0],
                            name,
                            name === fileData.names.takedown,
                        )}
                    />
                    {filesData?.[name] && (
                        <FileLink
                            hasDownload={!!isApple}
                            link={name === fileData?.names.takedown ? takedownDownloadUri : xmlDownloadUri}
                            name={filesData?.[name]?.name}
                        />
                    )}
                </Box>
            );
        default:
            return (
                <AutoCompleteMenu
                    defaultValue={selections?.[selectionKey]?.find((option) => option?.value === values?.[name])}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    label={label}
                    name={name}
                    optionLabel={(option) => option?.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => field.onChange(option?.value)}
                />
            );
        }
    };

    useEffect(() => {
        const newFilesData = {};

        if (values?.[fileData.names.xml]) newFilesData.xmlFile = { name: t("labels.xmlFileUploaded") };

        if (values?.[fileData.names.takedown]) newFilesData.takedownFile = { name: t("labels.takedownFileUploaded") };

        setFilesData(newFilesData);
    }, [values]); // eslint-disable-line

    useEffect(
        () => {
            setUploadInputPreviousValueHandler(
                watch,
                setError,
                setValue,
                filesData,
                fileData.names.xml,
            );
        },
        [watch(fileData.names.xml)], // eslint-disable-line
    );

    return (
        <FormContainer
            action={action}
            disabled={filesLoading?.xmlFile}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                finalizedFieldsArray,
                renderFormControllerChildrenHandler,
            )}
            hasSubmitButton={canDo(
                user,
                [mediaEditPermission, mediaReadPermission],
            )}
            onSubmitForm={(data) => onSubmitForm(data)}
        />
    );
};

export default ClientXmlFileForm;

ClientXmlFileForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,
};
