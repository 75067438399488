import { selectionsData } from "data";
import { SET_SELECTION } from "redux/types";
import { updateStateHandler } from "utils";

const {
    artworkFileNames,
    fileTypes,
    folderNames,
    mimeTypes,
    trackFileNames,
    trackFormats,
    xmlFileNames,
} = selectionsData.data;

const initialState = {
    artworkFileNames,
    fileTypes,
    folderNames,
    mimeTypes,
    trackFileNames,
    trackFormats,
    xmlFileNames,
};

const setSelection = (state, selection) => updateStateHandler(
    state,
    selection,
);

const selectionsReducer = (state = initialState, action) => {
    const {
        payload,
        type,
    } = action;

    const { selection } = payload || {};

    switch (type) {
    case SET_SELECTION: return setSelection(
        state,
        selection,
    );
    default: return state;
    }
};

export default selectionsReducer;
