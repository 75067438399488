import { gql } from "@apollo/client";

export const getCountriesSelectionListQuery = gql`
    query getCountriesSelectionList(
        $first: Int,
        $last: Int,
        $before: CountryWhereUniqueInput,
        $after: CountryWhereUniqueInput
    ) {
        countries(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            en_name
            territoryCode
        }
    }
`;

export const getGenresSelectionListQuery = gql`
    query getGenresSelectionList(
        $first: Int,
        $last: Int,
        $before: GenreWhereUniqueInput,
        $after: GenreWhereUniqueInput
    ) {
        genres(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getRolesSelectionListQuery = gql`
    query getRolesSelectionList {
        listRoles {
            id
            name
        }
    }
`;

export const getTrackFormatsSelectionListQuery = gql`
    query  trackFormats{
        trackFormats {
            id
            displayName
        }
    }
`;

export const getTrackTypesSelectionListQuery = gql`
    query getTrackTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: TrackTypeWhereUniqueInput,
        $after: TrackTypeWhereUniqueInput
    ) {
        trackTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getDistributionFileTypesSelectionListQuery = gql`
    query getDistributionFileTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: DistributeTypeWhereUniqueInput,
        $after: DistributeTypeWhereUniqueInput
    ) {
        distributeTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getDocumentTypesSelectionListQuery = gql`
    query getDocumentTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: DocumentTypeWhereUniqueInput,
        $after: DocumentTypeWhereUniqueInput
    ) {
        documentTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }

`;

export const getImageTypesSelectionListQuery = gql`
    query getImageTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: ImageTypeWhereUniqueInput,
        $after: ImageTypeWhereUniqueInput
    ) {
        imageTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getReleaseTypesSelectionListQuery = gql`
    query getReleaseTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: ReleaseTypeWhereUniqueInput,
        $after: ReleaseTypeWhereUniqueInput
    ) {
        releaseTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getRightTypesSelectionListQuery = gql`
    query getRightTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: RightTypeWhereUniqueInput,
        $after: RightTypeWhereUniqueInput
    ) {
        rightTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getVideoTypesSelectionListQuery = gql`
    query getVideoTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: VideoTypeWhereUniqueInput,
        $after: VideoTypeWhereUniqueInput
    ) {
        videoTypes(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getXmlFileTypesSelectionListQuery = gql`
    query getXmlFileTypesSelectionList(
        $first: Int,
        $last: Int,
        $before: DownloadXMLTypeWhereUniqueInput,
        $after: DownloadXMLTypeWhereUniqueInput
    ) {
        downloadXmlTypes (
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getBatchNamesSelectionListQuery = gql`
    query getBatchNamesSelectionList(
        $first: Int,
        $last: Int,
        $before: DistributeBatchNameWhereUniqueInput,
        $after: DistributeBatchNameWhereUniqueInput
    ) {
        distributeBatchNames(
            first: $first,
            last: $last,
            before: $before,
            after: $after
        ) {
            id
            name
        }
    }
`;

export const getClientXmlFileTreeSelectionListQuery = gql`
    query getClientXmlFileTreeSelectionList {
        getSelectionsMenuOptions {
            id
            selections
        }
    }
`;

export const getRevenueReportsSystemFieldsSelectionListQuery = gql`
    query getRevenueReportsSystemFieldsSelectionList {
        getRevenueMappingTemplate {
            posts {
                id
                key
                value
            }
        }
    }
`;
