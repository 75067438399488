import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useReleaseDistributionSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        client: yup.string().required(t("messages.inputMsgs.required")),
        fileType: yup.string().required(t("messages.inputMsgs.required")),
        releaseDate: yup.date().typeError(t("messages.inputMsgs.invalidDate")).required(t("messages.inputMsgs.required")),
        territory: yup.string().required(t("messages.inputMsgs.required")),
        trackFormat: yup.mixed().required(t("messages.inputMsgs.required")),
    });
};

export default useReleaseDistributionSchema;
