import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import LyricsIcon from "@mui/icons-material/Lyrics";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";

import constantsData from "./constants";
import permissionsData from "./permissions";
import urlsData from "./urls";

const {
    artists: { url: artistsRouteUrl },
    clients: { url: clientsRouteUrl },
    distributions: distributionsRouteUrl,
    home: homeRouteUrl,
    permissions: permissionsRouteUrl,
    releases: { url: releasesRouteUrl },
    revenueReports: { url: revenueReportsRouteUrl },
    territories: { url: territoriesRouteUrl },
    tracks: {
        formats: { url: tracksFormatsRouteUrl },
        url: tracksRouteUrl,
    },
    users: { url: usersRouteUrl },
    videos: videosRouteUrl,
} = urlsData.routes;

const {
    accountRead: accountReadPermission,
    mediaRead: mediaReadPermission,
    reportRead: reportReadPermission,
} = permissionsData.permissions.keys;

const sidebarData = {
    routes: {
        [artistsRouteUrl]: { id: 2 },
        [clientsRouteUrl]: { id: 9 },
        [distributionsRouteUrl]: { id: 4 },
        [homeRouteUrl]: { id: 1 },
        [permissionsRouteUrl]: { id: 12 },
        [releasesRouteUrl]: { id: 3 },
        [revenueReportsRouteUrl]: { id: 10 },
        [territoriesRouteUrl]: { id: 11 },
        [tracksFormatsRouteUrl]: { id: 6 },
        [tracksRouteUrl]: { id: 5 },
        [usersRouteUrl]: { id: 8 },
        [videosRouteUrl]: { id: 7 },
    },
    tabs: [
        {
            Icon: DashboardOutlinedIcon,
            id: 1,
            key: "home",
            permissions: [mediaReadPermission],
            routeUrl: homeRouteUrl,
        },
        {
            Icon: SpatialAudioOffIcon,
            id: 2,
            key: "artists",
            permissions: [mediaReadPermission],
            routeUrl: artistsRouteUrl,

        },
        {
            Icon: LibraryMusicIcon,
            id: 3,
            key: "releases",
            permissions: [mediaReadPermission],
            routeUrl: releasesRouteUrl,

        },
        {
            Icon: CallSplitIcon,
            id: 4,
            key: "distributions",
            permissions: [mediaReadPermission],
            routeUrl: distributionsRouteUrl,

        },
        {
            Icon: AlbumOutlinedIcon,
            id: 5,
            key: "tracks",
            permissions: [mediaReadPermission],
            routeUrl: tracksRouteUrl,

        },
        {
            Icon: LyricsIcon,
            id: 6,
            key: "tracksFormats",
            permissions: [mediaReadPermission],
            routeUrl: tracksFormatsRouteUrl,

        },
        {
            Icon: SmartDisplayOutlinedIcon,
            id: 7,
            key: "videos",
            permissions: [mediaReadPermission],
            routeUrl: videosRouteUrl,

        },
        {
            Icon: PeopleAltOutlinedIcon,
            id: 8,
            key: "users",
            permissions: [accountReadPermission],
            routeUrl: usersRouteUrl,

        },
        {
            Icon: ContactPhoneOutlinedIcon,
            id: 9,
            key: "clients",
            oneOfPermissions: true,
            permissions: [mediaReadPermission, reportReadPermission],
            routeUrl: clientsRouteUrl,

        },
        {
            ...(window?.__RUNTIME_CONFIG__?.ENABLE_REVENUE_REPORTS === "true") //eslint-disable-line
            && {
                Icon: RequestQuoteOutlinedIcon,
                id: 10,
                key: "revenueReports",
                permissions: [reportReadPermission],
                routeUrl: revenueReportsRouteUrl,

            },
        },
        {
            Icon: DonutSmallOutlinedIcon,
            id: 11,
            key: "territories",
            permissions: [mediaReadPermission],
            routeUrl: territoriesRouteUrl,
        },
        {
            Icon: VerifiedUserOutlinedIcon,
            hasRoles: true,
            id: 12,
            key: "permissions",
            roles: [constantsData.superAdmins],
            routeUrl: permissionsRouteUrl,
        },
    ],
};

export default sidebarData;
