import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { createTrackFormatMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateTrackFormat = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [createTrackFormatAction, { loading }] = useMutation(createTrackFormatMutation);

    const createTrackFormat = async ({
        description,
        name,
    }) => {
        try {
            await createTrackFormatAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t(
                            "messages.createMsgs.success",
                            { entity: t("tracksFormats.entity") },
                        ),
                        statusTypesData.success,
                    ));

                    navigate(urlsData.routes.tracks.formats.url);
                },
                variables: {
                    ...description && { description },
                    name,
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createTrackFormat,
        loading,
    };
};

export default useCreateTrackFormat;
