import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData, selectionsData, urlsData } from "data";
import { getTrackQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert, setSelection } from "redux/actions";
import { displayErrorHandler } from "utils";

const useGetTrack = (id) => {
    const { isAuthenticated } = useAuth0();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {
        stereoReleaseTracks: stereoReleaseTracksSelectionKey,
    } = selectionsData.keys;

    const {
        data,
        error,
        loading,
    } = useQuery(
        getTrackQuery,
        {
            onCompleted: ({ track }) => {
                const relatedStereoTracks = track.releaseTracks[0]?.release?.releaseTracks?.map(({
                    id: releaseTrackId,
                    track: trackData,
                }) => ({
                    ...trackData,
                    releaseTrackId,
                }));

                dispatch(setSelection({
                    [stereoReleaseTracksSelectionKey]: relatedStereoTracks.filter((trackInstance) => !trackInstance.originalTrackId).map(({
                        id: trackId,
                        title,
                    }) => ({
                        label: title,
                        value: trackId,
                    })),
                }));

                if (!track) navigate(urlsData.routes.notFound);
            },
            skip: !isAuthenticated || !id,
            variables: {
                where: { id },
            },
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        data: data?.track,
        loading,
    };
};

export default useGetTrack;
