import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { constantsData } from "data";
import { getTracksFormatsListQuery } from "graph";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useTracksFormatsList = () => {
    const dispatch = useDispatch();

    const { isAuthenticated } = useAuth0();

    const { t } = useTranslation();

    const {
        data,
        error,
        loading,
    } = useQuery(
        getTracksFormatsListQuery,
        {
            skip: !isAuthenticated,
        },
    );

    useEffect(
        () => {
            if (error?.message === constantsData.failedToFetch && !loading) {
                displayErrorHandler(
                    t("messages.networkFailure"),
                    dispatch,
                    openAlert,
                );
            }
        },
        [error, data], // eslint-disable-line
    );

    return {
        data: data?.trackFormats,
        loading,
    };
};

export default useTracksFormatsList;
