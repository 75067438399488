/* eslint-disable max-lines */

import { useMutation } from "@apollo/client";
import {
    constantsData,
    fileData,
    identifiersData,
    importerConfigData,
    namesData,
    statusTypesData,
} from "data";
import {
    createDynamicXmlFileMutation,
    createRevenueReportTemplateMutation,
    importReleaseClientLinksFileMutation,
    importRevenueReportFileMutation,
    importXmlFileMutation,
    saveUploadedFileMetadataMutation,
    saveUploadedTrackFileMetadataMutation,
    startResumableUploadFileSessionMutation,
} from "graph";
import { useCreateImage, useCreateReleaseLyricsFile, useCreateVideo } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    openAlert,
    setRefetchRevenueReportsList,
    setRevenueReportTemplateData,
    setXmlDownloadLink,
} from "redux/actions";
import {
    axios,
    checkFileValidationHandler,
    deleteFileHandler,
    displayErrorHandler,
    getReleaseFileErrorMessageHandler,
} from "utils";
import * as XLSX from "xlsx";

const useUploadFile = (
    trackId,
    releaseId,
    withoutRefetch,
    withoutAlert,
) => {
    const [controller, setController] = useState(null);

    const [filesData, setFilesData] = useState({});

    const [filesLoading, setFilesLoading] = useState({});

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        cancelRequest: cancelCreateImageRequest,
        createImage,
    } = useCreateImage(withoutAlert);

    const {
        cancelRequest: cancelCreateVideoRequest,
        createVideo,
    } = useCreateVideo(
        trackId,
        releaseId,
        withoutRefetch,
        withoutAlert,
    );

    const { createReleaseLyricsFile } = useCreateReleaseLyricsFile(trackId);

    const [createRevenueReportTemplateAction] = useMutation(createRevenueReportTemplateMutation);

    const [startResumableUploadFileSessionAction] = useMutation(startResumableUploadFileSessionMutation);

    const [saveUploadedFileMetadataAction] = useMutation(saveUploadedFileMetadataMutation);

    const [saveUploadedTrackFileMetadataAction] = useMutation(saveUploadedTrackFileMetadataMutation);

    const [importReleaseClientLinksFileAction] = useMutation(importReleaseClientLinksFileMutation);

    const [importRevenueReportFileAction] = useMutation(importRevenueReportFileMutation);

    const [importXmlFileAction] = useMutation(importXmlFileMutation);

    const [createDynamicXmlFileAction] = useMutation(createDynamicXmlFileMutation);

    const {
        document,
        image,
        lyrics,
        releaseClientLinks,
        revenueReport,
        revenueReportTemplate,
        sshKey,
        takedown,
        track,
        video,
        xml,
        xmlType,
    } = fileData.names;

    const { uploadFilePending: uploadFilePendingToastName } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const implementCathErrorHandler = (err, importerData) => {
        if (!withoutAlert) {
            displayErrorHandler(
                err?.message === constantsData.networkError || err?.message === constantsData.failedToFetch ? t("messages.networkFailure") : `${t(
                    "messages.uploadMsgs.fail",
                    { entity: t("labels.file") },
                )}${err?.message ? `, ${err?.message}` : ""}`,
                dispatch,
                openAlert,
            );
        }

        if (importerData) {
            if (importerData?.uploadingReleaseImage) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        release: {
                            data: importerData?.release,
                            message: getReleaseFileErrorMessageHandler(
                                err,
                                "uploadReleaseImageFailed",
                                t,
                            ),
                        },
                        ...(err.message === constantsData.networkError || err.message === constantsData.failedToFetch) && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled && { isStopped: true },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    releaseImage: {
                        ...prev.releaseImage,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError || err.message === constantsData.failedToFetch) importerData?.setImporting(false);
            }

            if (importerData?.uploadingTrackFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...(err.message === constantsData.networkError || err.message === constantsData.failedToFetch) && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadTrackFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadTrackFileFailed",
                                    t,
                                ),
                            },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    tracks: {
                        ...prev.tracks,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError || err.message === constantsData.failedToFetch) importerData?.setImporting(false);
            }

            if (importerData?.uploadingVideoFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...(err.message === constantsData.networkError || err.message === constantsData.failedToFetch) && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadVideoFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            release: { data: importerData?.release },
                            video: {
                                data: importerData?.video,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadVideoFileFailed",
                                    t,
                                ),
                            },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    videos: {
                        ...prev.videos,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError || err.message === constantsData.failedToFetch) importerData?.setImporting(false);
            }

            if (importerData?.uploadingDocumentFile) {
                dispatch(importerData?.setInvalidImporterRelease(
                    {
                        originalData: importerData?.originalData,
                        ...(err.message === constantsData.networkError || err.message === constantsData.failedToFetch) && { isNetworkFailed: true },
                        ...err.message === constantsData.canceled ? {
                            isStopped: true,
                            release: {
                                data: importerData?.release,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadDocumentFileFailed",
                                    t,
                                ),
                            },
                        } : {
                            document: {
                                data: importerData?.document,
                                message: getReleaseFileErrorMessageHandler(
                                    err,
                                    "uploadDocumentFileFailed",
                                    t,
                                ),
                            },
                            release: { data: importerData?.release },
                        },
                    },
                ));

                importerData?.setStatus((prev) => ({
                    ...prev,
                    documents: {
                        ...prev.documents,
                        loading: false,
                        success: false,
                    },
                }));

                if (err.message === constantsData.networkError || err.message === constantsData.failedToFetch) importerData?.setImporting(false);
            }
        }
    };

    const implementFinallyLogicHandler = (name, importerData) => {
        setFilesLoading((prev) => ({
            ...prev,
            [name]: false,
        }));

        setController(null);

        if (!importerData) toast.dismiss(`${uploadFilePendingToastName}_${toastId}`);

        if (importerData) importerData?.setCurrentStep(null);
    };

    const implementSuccessUploadHandler = (
        otherData,
        name,
        file,
        fileId,
        data,
        importerData,
    ) => {
        if (!otherData?.xmlForm) {
            setFilesData((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    ...fileId && { id: fileId },
                    name: file?.name,
                    ...name === releaseClientLinks && { data },
                },
            }));
        }

        if (importerData) {
            if (importerData?.uploadingTrackFile) {
                importerData?.setUploadedTracksFiles((prev) => [
                    ...prev,
                    {
                        ...importerData?.track,
                        fileId,
                    },
                ]);
            }

            if (importerData?.uploadingDocumentFile) {
                importerData?.setUploadedDocumentsFiles((prev) => [
                    ...prev,
                    {
                        ...importerData?.document,
                        fileId,
                    },
                ]);
            }
        }
    };

    // This function is created to handle the uploading process in chunks instead of the whole file.
    const uploadFileInChunksHandler = async (
        file,
        resumableSessionUrl,
        importerData,
        newController,
        name,
    ) => {
        const fileSize = file.size;

        const minChunkSize = 1024 * 1024;

        const maxChunkSize = 1024 * 1024 * 8;

        const fileSizeIdealChunk = Math.floor(fileSize / 10);

        const chunkSize = Math.min(
            Math.max(
                fileSizeIdealChunk,
                minChunkSize,
            ),
            maxChunkSize,
        );

        let start = 0;

        let end = 0;

        while (start !== undefined && end <= file.size) {
            end = Math.min(
                start + chunkSize,
                file.size,
            );

            const chunk = file.slice(
                start,
                end,
            );

            try {
                await axios( // eslint-disable-line
                    false,
                    { sessionUrl: resumableSessionUrl },
                    true,
                )({
                    data: chunk,
                    headers: {
                        "Content-Range": `bytes ${start}-${end - 1}/${fileSize}`,
                        "Content-Type": "application/octet-stream",
                    },
                    method: "PUT",
                    signal: newController.signal,
                    baseURL: `${window?.__RUNTIME_CONFIG__?.CMS_UPLOAD_FILES_PROXY_API}`, // eslint-disable-line
                });

                if (end === fileSize) break;
            } catch (err) {
                if (err?.response?.status === 308) start = parseInt(err.response.headers.range.split("-")[1]);
                // Retry uploading same chunk if the request is timeout (408)
                else if (err?.response?.status === 408) {
                    const oldStart = start;

                    start = oldStart;
                } else {
                    implementCathErrorHandler(
                        err,
                        importerData,
                    );

                    implementFinallyLogicHandler(
                        name,
                        importerData,
                    );

                    return {
                        error: true,
                        message: err,
                    };
                }
            }
        }
    };

    const uploadFile = async (
        file,
        formMethods,
        name,
        id,
        otherData,
    ) => {
        const {
            importerData,
            setModalData,
            templateData,
            videoType,
        } = otherData || {};

        if (checkFileValidationHandler(
            file,
            name,
        )) {
            setFilesLoading({
                ...filesLoading,
                [name]: true,
            });

            if (!importerData) {
                toast.info(
                    () => t(
                        "messages.uploadMsgs.pending",
                        { entity: t("labels.file") },
                    ),
                    { toastId: `${uploadFilePendingToastName}_${toastId}` },
                );
            }

            const newController = new AbortController();

            setController(newController);

            if (importerData?.uploadingReleaseImage) importerData?.setCurrentStep(importerConfigData.steps.uploadingReleaseImage);

            if (importerData?.uploadingTrackFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingTrackFile);

            if (importerData?.uploadingVideoFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingVideoFile);

            if (importerData?.uploadingDocumentFile) importerData?.setCurrentStep(importerConfigData.steps.uploadingDocumentFile);

            if (name === revenueReportTemplate) {
                const bufferedXlsxFile = await file.arrayBuffer();

                const xlsxFileReader = XLSX.read(
                    bufferedXlsxFile,
                    { cellDates: true },
                );

                const xlsxFileSheet = xlsxFileReader.Sheets[xlsxFileReader.SheetNames[0]];

                const xlsxFileJsonData = XLSX.utils.sheet_to_json(
                    xlsxFileSheet,
                    { defval: "" },
                );

                const xlsxFileData = xlsxFileJsonData[0];

                await createRevenueReportTemplateAction({
                    context: {
                        fetchOptions: { signal: newController.signal },
                    },
                    onCompleted: ({ createRevenueTemplate }) => {
                        if (createRevenueTemplate) {
                            const {
                                id: templateId,
                                templateName,
                                templateObj,
                            } = createRevenueTemplate || {};

                            dispatch(setRevenueReportTemplateData({
                                data: templateObj,
                                id: templateId,
                                name: templateName,
                            }));

                            setModalData((prev) => ({
                                ...prev,
                                open: false,
                            }));

                            dispatch(openAlert(
                                t(
                                    "messages.uploadMsgs.success",
                                    { entity: t("labels.template") },
                                ),
                                statusTypesData.success,
                            ));
                        }

                        implementSuccessUploadHandler(
                            otherData,
                            name,
                            file,
                            createRevenueTemplate?.id,
                            createRevenueTemplate,
                            importerData,
                        );

                        implementFinallyLogicHandler(
                            name,
                            importerData,
                        );
                    },
                    onError: (err) => {
                        implementCathErrorHandler(
                            err,
                            importerData,
                        );

                        implementFinallyLogicHandler(
                            name,
                            importerData,
                        );
                    },
                    variables: {
                        clientId: id,
                        fileHeaderFields: Object.keys(xlsxFileData).map((key) => key),
                        templateName: templateData,
                    },
                });
            } else {
                try {
                    let resumableSessionUrlCounter = 0;

                    const getResumableSessionUrlHandler = async () => {
                        await startResumableUploadFileSessionAction({
                            context: {
                                fetchOptions: { signal: newController.signal },
                            },
                            onCompleted: async ({ startResumableUploadSession }) => {
                                await uploadFileInChunksHandler(
                                    file,
                                    startResumableUploadSession?.resumableSessionUrl,
                                    importerData,
                                    newController,
                                    name,
                                ).then(async (response) => {
                                    if (!response?.error) {
                                        switch (name) {
                                        case track:
                                            await saveUploadedTrackFileMetadataAction({
                                                context: {
                                                    fetchOptions: { signal: newController.signal },
                                                },
                                                onCompleted: ({ saveTrackMetadata }) => {
                                                    formMethods.setValue(
                                                        name,
                                                        saveTrackMetadata?.id,
                                                    );

                                                    implementSuccessUploadHandler(
                                                        otherData,
                                                        name,
                                                        file,
                                                        saveTrackMetadata?.id,
                                                        saveTrackMetadata,
                                                        importerData,
                                                    );

                                                    implementFinallyLogicHandler(
                                                        name,
                                                        importerData,
                                                    );
                                                },
                                                onError: (err) => {
                                                    implementCathErrorHandler(
                                                        err,
                                                        importerData,
                                                    );

                                                    implementFinallyLogicHandler(
                                                        name,
                                                        importerData,
                                                    );
                                                },
                                                variables: { fileName: startResumableUploadSession?.fileName },
                                            });
                                            break;
                                        case image:
                                        case video:
                                        case document:
                                        case lyrics:
                                        case sshKey:
                                            await saveUploadedFileMetadataAction({
                                                context: {
                                                    fetchOptions: { signal: newController.signal },
                                                },
                                                onCompleted: async ({ saveUploadedFileMetadata }) => {
                                                    switch (name) {
                                                    case image:
                                                        await createImage(
                                                            saveUploadedFileMetadata?.id,
                                                            formMethods,
                                                            name,
                                                            importerData,
                                                            setFilesData,
                                                        );
                                                        break;
                                                    case video:
                                                        await createVideo(
                                                            saveUploadedFileMetadata?.id,
                                                            videoType,
                                                            importerData,
                                                        );
                                                        break;
                                                    case lyrics:
                                                        await createReleaseLyricsFile(saveUploadedFileMetadata?.id);
                                                        break;
                                                    case document:
                                                    case sshKey:
                                                        formMethods.setValue(
                                                            name,
                                                            saveUploadedFileMetadata?.id,
                                                        );
                                                        break;
                                                    }

                                                    implementSuccessUploadHandler(
                                                        otherData,
                                                        name,
                                                        file,
                                                        saveUploadedFileMetadata?.id,
                                                        saveUploadedFileMetadata,
                                                        importerData,
                                                    );

                                                    implementFinallyLogicHandler(
                                                        name,
                                                        importerData,
                                                    );
                                                },
                                                onError: (err) => {
                                                    implementCathErrorHandler(
                                                        err,
                                                        importerData,
                                                    );

                                                    implementFinallyLogicHandler(
                                                        name,
                                                        importerData,
                                                    );
                                                },
                                                variables: {
                                                    fileName: startResumableUploadSession?.fileName,
                                                    type: fileData.uploadedTypes[name],
                                                },
                                            });
                                            break;
                                        case releaseClientLinks:
                                        case revenueReport:
                                        case xmlType:
                                        case xml:
                                        case takedown:
                                            switch (name) {
                                            case xml:
                                            case xmlType:
                                            case takedown:
                                                if (otherData?.xmlForm) {
                                                    await createDynamicXmlFileAction({
                                                        context: {
                                                            fetchOptions: { signal: newController.signal },
                                                        },
                                                        onCompleted: ({ createdynamicXML }) => {
                                                            deleteFileHandler(
                                                                setFilesData,
                                                                formMethods,
                                                                name,
                                                            );

                                                            if (createdynamicXML) {
                                                                dispatch(setXmlDownloadLink(createdynamicXML));

                                                                dispatch(openAlert(
                                                                    t(
                                                                        "messages.uploadMsgs.success",
                                                                        { entity: t("labels.xml") },
                                                                    ),
                                                                    statusTypesData.success,
                                                                ));
                                                            }

                                                            implementSuccessUploadHandler(
                                                                otherData,
                                                                name,
                                                                file,
                                                                null,
                                                                createdynamicXML,
                                                                importerData,
                                                            );

                                                            implementFinallyLogicHandler(
                                                                name,
                                                                importerData,
                                                            );
                                                        },
                                                        onError: (err) => {
                                                            implementCathErrorHandler(
                                                                err,
                                                                importerData,
                                                            );

                                                            implementFinallyLogicHandler(
                                                                name,
                                                                importerData,
                                                            );
                                                        },
                                                        variables: {
                                                            ...otherData,
                                                            fileName: startResumableUploadSession?.fileName,
                                                        },
                                                    });
                                                } else {
                                                    await importXmlFileAction({
                                                        context: {
                                                            fetchOptions: { signal: newController.signal },
                                                        },
                                                        onCompleted: ({ uploadXMLFIle }) => {
                                                            implementSuccessUploadHandler(
                                                                otherData,
                                                                name,
                                                                file,
                                                                null,
                                                                uploadXMLFIle,
                                                                importerData,
                                                            );

                                                            implementFinallyLogicHandler(
                                                                name,
                                                                importerData,
                                                            );
                                                        },
                                                        variables: {
                                                            clientId: id,
                                                            fileName: startResumableUploadSession?.fileName,
                                                        },
                                                    });
                                                }
                                                break;
                                            case revenueReport:
                                                await importRevenueReportFileAction({
                                                    context: {
                                                        fetchOptions: { signal: newController.signal },
                                                    },
                                                    onCompleted: ({ importRevenueReport }) => {
                                                        if (importRevenueReport) {
                                                            dispatch(setRefetchRevenueReportsList(true));

                                                            setModalData((prev) => ({
                                                                ...prev,
                                                                open: false,
                                                            }));

                                                            dispatch(openAlert(
                                                                t(
                                                                    "messages.uploadMsgs.success",
                                                                    { entity: t("revenueReports.entity") },
                                                                ),
                                                                statusTypesData.success,
                                                            ));
                                                        }

                                                        formMethods.setValue(
                                                            name,
                                                            importRevenueReport?.id,
                                                        );

                                                        implementSuccessUploadHandler(
                                                            otherData,
                                                            name,
                                                            file,
                                                            importRevenueReport?.id,
                                                            importRevenueReport,
                                                            importerData,
                                                        );

                                                        implementFinallyLogicHandler(
                                                            name,
                                                            importerData,
                                                        );
                                                    },
                                                    onError: (err) => {
                                                        implementCathErrorHandler(
                                                            err,
                                                            importerData,
                                                        );

                                                        implementFinallyLogicHandler(
                                                            name,
                                                            importerData,
                                                        );
                                                    },
                                                    variables: {
                                                        clientId: id,
                                                        fileName: startResumableUploadSession?.fileName,
                                                        templateId: templateData,
                                                    },
                                                });
                                                break;
                                            case releaseClientLinks:
                                                await importReleaseClientLinksFileAction({
                                                    context: {
                                                        fetchOptions: { signal: newController.signal },
                                                    },
                                                    onCompleted: ({ importReleaseLinks }) => {
                                                        implementSuccessUploadHandler(
                                                            otherData,
                                                            name,
                                                            file,
                                                            null,
                                                            importReleaseLinks,
                                                            importerData,
                                                        );

                                                        implementFinallyLogicHandler(
                                                            name,
                                                            importerData,
                                                        );
                                                    },
                                                    variables: { fileName: startResumableUploadSession?.fileName },
                                                });
                                                break;
                                            }
                                            break;
                                        }
                                    }
                                });
                            },
                            onError: (err) => {
                                if (resumableSessionUrlCounter <= 2) {
                                    getResumableSessionUrlHandler();

                                    resumableSessionUrlCounter += 1;
                                } else {
                                    implementCathErrorHandler(
                                        err,
                                        importerData,
                                    );

                                    implementFinallyLogicHandler(
                                        name,
                                        importerData,
                                    );
                                }
                            },
                            variables: {
                                fileName: file.name,
                                securedNaming: !(name === fileData.names.xmlType || name === fileData.names.takedown),
                                type: fileData.uploadedTypes[name],
                            },
                        });
                    };

                    getResumableSessionUrlHandler();
                } catch (err) {
                    implementCathErrorHandler(
                        err,
                        importerData,
                    );

                    implementFinallyLogicHandler(
                        name,
                        importerData,
                    );
                }
            }
        } else {
            if (!withoutAlert) {
                dispatch(openAlert(
                    t("messages.wrongFormat"),
                    statusTypesData.error,
                ));
            }

            if (importerData) {
                if (importerData?.uploadingReleaseImage) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: {
                                data: importerData?.release,
                                message: t("messages.importerMsgs.wrongReleaseImageFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        releaseImage: {
                            ...prev.releaseImage,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingTrackFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongTrackFileFormat"),
                            },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        tracks: {
                            ...prev.tracks,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingVideoFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                            track: {
                                data: importerData?.track,
                                message: t("messages.importerMsgs.wrongVideoFileFormat"),
                            },
                        },

                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        videos: {
                            ...prev.videos,
                            loading: false,
                            success: false,
                        },
                    }));
                }

                if (importerData?.uploadingDocumentFile) {
                    dispatch(importerData.setInvalidImporterRelease(
                        {
                            document: {
                                data: importerData?.document,
                                message: t("messages.importerMsgs.wrongDocumentFileFormat"),
                            },
                            originalData: importerData?.originalData,
                            release: { data: importerData?.release },
                        },
                    ));

                    importerData?.setStatus((prev) => ({
                        ...prev,
                        documents: {
                            ...prev.documents,
                            loading: false,
                            success: false,
                        },
                    }));
                }
            }
        }
    };

    return {
        cancelCreateImageRequest,
        cancelCreateVideoRequest,
        cancelRequest: () => controller && controller.abort(),
        filesData,
        filesLoading,
        setFilesData,
        uploadFile,
    };
};

export default useUploadFile;
