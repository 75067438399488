import { gql } from "@apollo/client";

export const createDistributionMutation = gql`
    mutation createDistribution(
        $releaseId: Int!,
        $clientId: Int!,
        $territoryId: Int!,
        $distributionFileTypeId: Int!,
        $releaseDate: DateTime!
        $tracksFormat: [Int]!
        ) {
            distribute(
                releaseId: $releaseId,
                clientId: $clientId,
                territoryId: $territoryId,
                distributeTypeId: $distributionFileTypeId,
                releaseDate: $releaseDate
                tracksformat: $tracksFormat
                )
                }
                `;

export const createBulkDistributionsMutation = gql`
                mutation createBulkDistributions(
                    $releasesIds: [Int]!,
                    $clientId: Int!,
                    $territoryId: Int!,
                    $distributionFileTypeId: Int!,
                    $releaseDate: DateTime!
                    $tracksFormat: [Int]!
                    ) {
                        bulkDistribute(
                            releaseIds: $releasesIds,
                            clientId: $clientId,
                            territoryId: $territoryId,
                            distributeTypeId: $distributionFileTypeId,
                            releaseDate: $releaseDate
                            tracksformat: $tracksFormat
                    )
                }
            `;

export const deleteDistributionMutation = gql`
    mutation deleteDistribution($id: Int!) {
        deleteDistributionJob(jobId: $id)
    }
`;

export const retryFailedDistributionMutation = gql`
    mutation retryFailedDistribution($id: Int!) {
        retryDistributionJob(jobId: $id)
    }
`;

export const takeDownAppleDistributionMutation = gql`
    mutation takedownDistribution($id: Int!, $commandMode:CommandMode!) {
        takedownDistribution(jobId: $id,commandMode:$commandMode)
    }
`;
