import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useTracksFormatsSchema = () => {
    const { t } = useTranslation();

    return yup.object({
        description: yup.string().nullable(),
        name: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useTracksFormatsSchema;
