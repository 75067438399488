import { yupResolver } from "@hookform/resolvers/yup";
import { AutoCompleteMenu, Calendar, Loader } from "atoms";
import { FormContainer } from "components";
import { inputData, releaseDistributionFormData, selectionsData } from "data";
import {
    useClientsList,
    useDistributionFileTypesSelectionList,
    useTerritoriesList,
    useTrackFormatsSelectionList,
} from "hooks";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useReleaseDistributionSchema } from "schemas";
import { renderFormControllerHandler } from "utils";

const ReleaseDistributionForm = ({
    action,
    loading,
    onSubmitForm,
    values,
}) => {
    const schema = useReleaseDistributionSchema();

    const selections = useSelector((state) => state.selectionsReducer);

    const formMethods = useForm({
        defaultValues: values,
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const {
        count: clientsCount,
        fetched: clientsFetched,
        loading: clientsLoading,
        page: clientsPage,
        refetch: clientsRefetch,
        searchKey: clientsSearchKey,
    } = useClientsList(
        true,
        true,
    );

    const {
        count: territoriesCount,
        fetched: territoriesFetched,
        loading: territoriesLoading,
        page: territoriesPage,
        refetch: territoriesRefetch,
        searchKey: territoriesSearchKey,
    } = useTerritoriesList(
        true,
        true,
    );

    const { loading: distributionFileTypesSelectionListLoading } = useDistributionFileTypesSelectionList();

    const { loading: trackFormatsSelectionListLoading } = useTrackFormatsSelectionList();

    const {
        control,
        formState: { errors },
    } = formMethods;

    const asyncMenus = {
        clients: {
            count: clientsCount,
            loading: clientsLoading,
            page: clientsPage,
            refetch: clientsRefetch,
            searchKey: clientsSearchKey,
            selectionKey: selectionsData.keys.clients,
        },
        territories: {
            count: territoriesCount,
            loading: territoriesLoading,
            page: territoriesPage,
            refetch: territoriesRefetch,
            searchKey: territoriesSearchKey,
            selectionKey: selectionsData.keys.territories,
        },
    };

    const renderFormControllerChildrenHandler = (
        field,
        label,
        name,
        type,
        isOptional,
        selectionKey,
        isAsyncMenu,
    ) => {
        switch (type) {
        case inputData.types.date: return (
            <Calendar
                errors={errors}
                field={field}
                isOptional={isOptional}
                label={label}
                name={name}
                value={field.value || null}
                hasTime
            />
        );

        case inputData.types.multiAutoComplete:

            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    defaultValue={values ? values[`${name}Array`] : []}
                    disabledOption={{}}
                    errors={errors}
                    getRenderedOption={(option) => option?.label}
                    isAsyncMenu={isAsyncMenu}
                    isOptional={isOptional}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={selections?.[selectionKey]}
                    isMultiple
                    onChange={(_, options) => {
                        console.log(options);

                        if (options) field.onChange(options);
                        else field.onChange([]);
                    }}
                />
            );

        default:

            return (
                <AutoCompleteMenu
                    asyncMenuData={asyncMenus?.[selectionKey]}
                    errors={errors}
                    getRenderedOption={(option) => option.label}
                    isAsyncMenu={isAsyncMenu}
                    label={label}
                    name={name}
                    optionLabel={(option) => option.label}
                    options={selections?.[selectionKey]}
                    onChange={(_, option) => {
                        if (option) field.onChange(option.value);
                        else field.onChange("");
                    }}
                />
            );
        }
    };

    if ((clientsLoading && !clientsFetched) || (territoriesLoading && !territoriesFetched) || distributionFileTypesSelectionListLoading || trackFormatsSelectionListLoading) return <Loader withoutFullHeight />;

    return (
        <FormContainer
            action={action}
            formMethods={formMethods}
            loading={loading}
            data={renderFormControllerHandler(
                control,
                releaseDistributionFormData,
                renderFormControllerChildrenHandler,
            )}
            withoutShadow
            onSubmitForm={onSubmitForm}
        />
    );
};

export default ReleaseDistributionForm;

ReleaseDistributionForm.propTypes = {
    action: PropTypes.string,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func,
    values: PropTypes.object,

};
