import {
    Avatar,
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Loader } from "atoms";
import { NoData } from "components";
import { identifiersData } from "data";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { StyledLink, StyledList } from "styles";
import { theme } from "theme";
import { getSubstringHandler } from "utils";

const List = ({
    actionsDisabled,
    comparingData,
    data,
    icons,
    loading,
    routeUrl,
    subRouteUrl,
    title,
    withoutComparingData,
}) => {
    const { t } = useTranslation();

    const {
        button: buttonId,
        link: linkId,
        list: listId,
    } = identifiersData.ids;

    return (
        <>
            {title && (
                <Typography
                    marginBlock={2}
                    variant="h6"
                >
                    {title}
                </Typography>
            )}
            <StyledList
                length={data?.length}
                loading={loading}
            >
                {loading ? <Loader withoutFullHeight /> : (
                    <Box
                        height="fit-content"
                        maxHeight={300}
                        overflow="auto"
                        width="100%"
                    >
                        {data?.length > 0 ? data?.map(({
                            entityId,
                            fallbackImage,
                            id,
                            image,
                            name,
                        }, index) => (
                            <Box
                                key={id}
                                width="100%"
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        gap={1}
                                        sx={{
                                            "& svg": { color: `${theme.palette.grey[100]}!important` },
                                        }}
                                    >
                                        {(image || fallbackImage) && (
                                            <Avatar
                                                alt={t("imageAlternatives.image")}
                                                imgProps={{ loading: "lazy" }}
                                                src={`${image?.downloadThumbnail ? window?.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_DOMAIN + image?.downloadThumbnail : ""}`} // eslint-disable-line
                                            >
                                                {fallbackImage}
                                            </Avatar>
                                        )}
                                        {routeUrl ? (
                                            <StyledLink
                                                id={`${listId}_${linkId}_${index + 1}`}
                                                to={`${routeUrl}/${id}${subRouteUrl}`}
                                            >
                                                {getSubstringHandler(name)}
                                            </StyledLink>
                                        ) : <Typography variant="caption">{name}</Typography>}
                                    </Box>
                                    {!withoutComparingData && comparingData?.length > 0 && comparingData.find(({ id: comparingId }) => comparingId === id) ? (
                                        <Tooltip title={icons[0].tooltip}>
                                            {icons[0]?.action ? (
                                                <IconButton
                                                    disabled={actionsDisabled}
                                                    id={`${icons[0]?.name}_${buttonId}_${index + 1}`}
                                                    sx={{ opacity: actionsDisabled ? 0.5 : 1 }}
                                                    onClick={() => icons[0]?.action(
                                                        id,
                                                        name,
                                                    )}
                                                >
                                                    {icons[0].Icon}
                                                </IconButton>
                                            ) : icons[0].Icon}
                                        </Tooltip>
                                    ) : !withoutComparingData && (
                                        <Tooltip title={icons[1].tooltip}>
                                            {icons[1]?.action ? (
                                                <IconButton
                                                    disabled={actionsDisabled}
                                                    id={`${icons[1]?.name}_${buttonId}_${index + 1}`}
                                                    sx={{ opacity: actionsDisabled ? 0.5 : 1 }}
                                                    onClick={() => icons[1]?.action(
                                                        id,
                                                        name,
                                                    )}
                                                >
                                                    {icons[1].Icon}
                                                </IconButton>
                                            ) : icons[1].Icon}
                                        </Tooltip>
                                    )}
                                    {withoutComparingData && (
                                        <Tooltip title={icons[0].tooltip}>
                                            {icons[0]?.action ? (
                                                <IconButton
                                                    disabled={actionsDisabled}
                                                    id={`${icons[0]?.name}_${buttonId}_${index + 1}`}
                                                    sx={{ opacity: actionsDisabled ? 0.5 : 1 }}
                                                    onClick={() => icons[0]?.action(
                                                        entityId || id,
                                                        name,
                                                    )}
                                                >
                                                    {icons[0].Icon}
                                                </IconButton>
                                            ) : icons[0].Icon}

                                        </Tooltip>
                                    )}
                                </Box>
                                {index !== data?.length - 1 && ( // eslint-disable-line
                                    <Divider
                                        sx={{ marginBlock: 2 }}
                                        variant="middle"
                                    />
                                )}
                            </Box>
                        )) : <NoData />}
                    </Box>
                )}
            </StyledList>
        </>
    );
};

export default List;

List.propTypes = {
    actionsDisabled: PropTypes.bool,
    comparingData: PropTypes.array,
    data: PropTypes.array,
    icons: PropTypes.array,
    loading: PropTypes.bool,
    routeUrl: PropTypes.string,
    subRouteUrl: PropTypes.string,
    title: PropTypes.string,
    withoutComparingData: PropTypes.bool,
};
