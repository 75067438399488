import { gql } from "@apollo/client";

export const getTracksFormatsListQuery = gql`
    query TrackFormats {
        trackFormats {
            description
            displayName
            id
        }
    }
`;

export const getTrackFormatQuery = gql`
    query TrackFormat($trackFormatId: Int!) {
        trackFormat(id: $trackFormatId) {
            description
            displayName
            id
        }
    }
`;
