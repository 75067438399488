import { gql } from "@apollo/client";

export const createTrackFormatMutation = gql`
    mutation CreateOneTrackFormat($name: String!, $description: String) {
        createOneTrackFormat(displayName: $name, description: $description) {
            id
        }
    }
`;

export const editTrackFormatMutation = gql`
    mutation UpdateTrackFormat($id: Int!, $name: String!, $description: String) {
        updateTrackFormat(id: $id, displayName: $name, description: $description) {
            id
            displayName
            description
        }
    }
`;

export const deleteTrackFormatMutation = gql`
    mutation DeleteTrackFormat($id: Int!) {
        deleteTrackFormat(id: $id) {
            id
        }
    }
`;
