import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData } from "data";
import { createDistributionMutation, getDistributionsListQuery } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openAlert, toggleDistributionDrawer } from "redux/actions";
import { displayErrorHandler } from "utils";

const useCreateDistribution = (
    releaseId,
    hasRefetch,
    limit,
    page,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createDistributionAction, { loading }] = useMutation(
        createDistributionMutation,
        {
            ...hasRefetch && {
                awaitRefetchQueries: true,
                refetchQueries: [
                    {
                        query: getDistributionsListQuery,
                        variables: {
                            limit,
                            page,
                            ...releaseId && { releaseId },
                        },
                    },
                ],
            },
        },
    );

    const createDistribution = async ({
        client,
        fileType,
        releaseDate,
        territory,
        trackFormat,
    }) => {
        try {
            await createDistributionAction({
                onCompleted: () => {
                    dispatch(openAlert(
                        t("messages.distributionMsgs.success"),
                        statusTypesData.success,
                    ));

                    dispatch(toggleDistributionDrawer(false));
                },
                variables: {
                    clientId: client,
                    distributionFileTypeId: fileType,
                    releaseDate,
                    releaseId,
                    territoryId: territory,
                    tracksFormat: trackFormat.map((format) => format.value),
                },
            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        createDistribution,
        loading,
    };
};

export default useCreateDistribution;
