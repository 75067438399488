import { regexData } from "data";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useClientInfoSchema = (isEdit = false) => {
    const { t } = useTranslation();

    const { number: numberRegex } = regexData;

    return yup.object({
        ftpBaseFolder: yup.
            string().
            when(
                "isApple",
                {
                    is: false,
                    otherwise: () => yup.string()["default"]("-"),
                    then: () => yup.string().required(t("messages.inputMsgs.required")),
                },
            ),
        ftpHost: yup.string().required(t("messages.inputMsgs.required")),
        ftpPassword: yup.string().when(
            "isApple",
            {
                is: (isApple) => isApple && !isEdit,
                otherwise: () => yup.string(),
                then: () => yup.string().required(t("messages.inputMsgs.required")),
            },
        ),
        ftpPort: yup.
            string().
            matches(
                numberRegex,
                t("messages.inputMsgs.invalidNumber"),
            ).
            when(
                "isApple",
                {
                    is: false,
                    otherwise: () => yup.string()["default"]("0"),
                    then: () => yup.string().required(t("messages.inputMsgs.required")),
                },
            ),
        ftpUsername: yup.string().required(t("messages.inputMsgs.required")),
        imageHeight: yup.
            string().
            matches(
                numberRegex,
                t("messages.inputMsgs.invalidNumber"),
            ).
            required(t("messages.inputMsgs.required")),
        imageType: yup.string().required(t("messages.inputMsgs.required")),
        imageWidth: yup.
            string().
            matches(
                numberRegex,
                t("messages.inputMsgs.invalidNumber"),
            ).
            required(t("messages.inputMsgs.required")),
        isApple: yup["boolean"]().required(t("messages.inputMsgs.required"))["default"](false),
        name: yup.string().required(t("messages.inputMsgs.required")),
        pdap: yup.string().required(t("messages.inputMsgs.required")),
        pdapName: yup.string().required(t("messages.inputMsgs.required")),
        secured: yup["boolean"]().required(t("messages.inputMsgs.required")),
        sshKeyFile: yup.mixed(),
        trackType: yup.string().required(t("messages.inputMsgs.required")),
    });
};

export default useClientInfoSchema;
