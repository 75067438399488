const { default: inputData } = require("data/input");

const {
    description: descriptionName,
    name: nameInput,
} = inputData.names;

const {
    description: descriptionLabel,
    name: nameLabel,
} = inputData.labels;

const {
    text: textInputType,
    textArea: textAreaInputType,
} = inputData.types;

export const tracksFormatsFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        label: nameLabel,
        name: nameInput,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: descriptionLabel,
        name: descriptionName,
        type: textAreaInputType,
    },
];

export default tracksFormatsFormData;
