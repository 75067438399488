import fileData from "data/file";
import inputData from "data/input";
import selectionsData from "data/selections";

const {
    artists: artistsSelectionKey,
    stereoReleaseTracks: stereoReleaseTracksSelectionKey,
    trackFormats: trackFormatsSelectionKey,
} = selectionsData.keys;

const {
    image: imageFileName,
    track: trackFileName,
} = fileData.names;

const {
    autoComplete: autoCompleteInputType,
    file: fileInputType,
    multiAutoComplete: multiAutoCompleteInputType,
    text: textInputType,
    textArea: textAreaInputType,
} = inputData.types;

const {
    composers: composersInputName,
    customId: customIdInputName,
    isrc: isrcInputName,
    iswc: iswcInputName,
    nameAr: nameArInputName,
    nameEn: nameEnInputName,
    number: numberInputName,
    originalTrack: originalTrackInputName,
    otherIsrc: otherIsrcInputName,
    primaryArtist: primaryArtistInputName,
    secondaryArtists: secondaryArtistsInputName,
    trackFormats: trackFormatsInputName,
    writers: writersInputName,
} = inputData.names;

const {
    composers: composersInputLabel,
    customId: customIdInputLabel,
    image: imageFileInputLabel,
    isrc: isrcInputLabel,
    iswc: iswcInputLabel,
    nameAr: nameArInputLabel,
    nameEn: nameEnInputLabel,
    number: numberInputLabel,
    originalTrack: originalTrackInputLabel,
    otherIsrc: otherIsrcInputLabel,
    primaryArtist: primaryArtistInputLabel,
    secondaryArtists: secondaryArtistsInputLabel,
    track: trackFileInputLabel,
    trackFormats: trackFormatsInputLabel,
    writers: writersInputLabel,
} = inputData.labels;

const trackFormData = [
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        label: isrcInputLabel,
        name: isrcInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: otherIsrcInputLabel,
        name: otherIsrcInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        label: nameEnInputLabel,
        name: nameEnInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        label: nameArInputLabel,
        name: nameArInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        isOptional: true,
        label: iswcInputLabel,
        name: iswcInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        label: customIdInputLabel,
        name: customIdInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        label: numberInputLabel,
        name: numberInputName,
        type: textInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        isAsyncMenu: true,
        label: primaryArtistInputLabel,
        name: primaryArtistInputName,
        selectionKey: artistsSelectionKey,
        type: autoCompleteInputType,
    },

    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        isAsyncMenu: true,
        isOptional: true,
        label: secondaryArtistsInputLabel,
        name: secondaryArtistsInputName,
        selectionKey: artistsSelectionKey,
        type: multiAutoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        isAsyncMenu: true,
        label: trackFormatsInputLabel,
        name: trackFormatsInputName,
        selectionKey: trackFormatsSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            xs: 12,
        },
        hasOffset: true,
        label: originalTrackInputLabel,
        name: originalTrackInputName,
        selectionKey: stereoReleaseTracksSelectionKey,
        type: autoCompleteInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        isOptional: true,
        label: writersInputLabel,
        name: writersInputName,
        type: textAreaInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        hasOffset: true,
        isOptional: true,
        label: composersInputLabel,
        name: composersInputName,
        type: textAreaInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        isOptional: true,
        label: imageFileInputLabel,
        name: imageFileName,
        type: fileInputType,
    },
    {
        col: {
            md: 4,
            xs: 12,
        },
        drawerCol: {
            md: 6,
            xs: 12,
        },
        isOptional: true,
        label: trackFileInputLabel,
        name: trackFileName,
        type: fileInputType,
    },
];

export default trackFormData;
