import { useMutation } from "@apollo/client";
import { constantsData, statusTypesData, urlsData } from "data";
import { editTrackFormatMutation } from "graph";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useEditTrackFormat = (id) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [editTrackFormatAction, { loading }] = useMutation(editTrackFormatMutation);

    const editTrackFormat = async ({
        description,
        name,
    }) => {
        try {
            await editTrackFormatAction({
                onCompleted: () => {
                    navigate(urlsData.routes.tracks.formats.url);

                    dispatch(openAlert(
                        t(
                            "messages.editMsgs.success",
                            { entity: t("tracksFormats.entity") },
                        ),
                        statusTypesData.success,
                    ));
                },
                variables: {
                    description,
                    id,
                    name,
                },

            });
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        }
    };

    return {
        editTrackFormat,
        loading,
    };
};

export default useEditTrackFormat;
